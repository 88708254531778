"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.templates = void 0;
const jsx_runtime_1 = require("preact/jsx-runtime");
exports.templates = {
    poweredBy: ({ hostname }) => {
        const escapedHostname = encodeURIComponent(hostname);
        return (jsx_runtime_1.jsxs("div", Object.assign({ className: "aa-powered-by" }, { children: ["Search by", jsx_runtime_1.jsx("a", Object.assign({ href: `https://www.algolia.com/?utm_source=netlify&utm_medium=link&utm_campaign=autocomplete-${escapedHostname}`, className: "aa-powered-by-link" }, { children: "Algolia" }), void 0)] }), void 0));
    },
    item: (hit, components) => {
        var _a, _b;
        return (jsx_runtime_1.jsx("a", Object.assign({ href: hit.url }, { children: jsx_runtime_1.jsxs("div", Object.assign({ className: "aa-ItemContent" }, { children: [jsx_runtime_1.jsx("div", Object.assign({ className: "aa-ItemIcon" }, { children: jsx_runtime_1.jsx("svg", Object.assign({ width: "20", height: "20", viewBox: "0 0 20 20" }, { children: jsx_runtime_1.jsx("path", { d: "M17 6v12c0 .52-.2 1-1 1H4c-.7 0-1-.33-1-1V2c0-.55.42-1 1-1h8l5 5zM14 8h-3.13c-.51 0-.87-.34-.87-.87V4", stroke: "currentColor", fill: "none", fillRule: "evenodd", strokeLinejoin: "round" }, void 0) }), void 0) }), void 0), jsx_runtime_1.jsxs("div", { children: [jsx_runtime_1.jsx("div", Object.assign({ className: "aa-ItemTitle" }, { children: (_b = (_a = hit.hierarchy) === null || _a === void 0 ? void 0 : _a.lvl0) !== null && _b !== void 0 ? _b : (jsx_runtime_1.jsx(components.Highlight, { hit: hit, attribute: "title" }, void 0)) }), void 0), hit.hierarchy && (jsx_runtime_1.jsx("div", Object.assign({ className: "aa-ItemHierarchy" }, { children: hierarchyToBreadcrumbs(hit, components) }), void 0)), jsx_runtime_1.jsx("div", Object.assign({ className: "aa-ItemDescription" }, { children: getSuggestionSnippet(hit, components) }), void 0)] }, void 0)] }), void 0) }), void 0));
    },
};
/**
 * Transform a highlighted hierarchy object into an array of Highlighted elements.
 * 3 levels max are returned.
 *
 * @param hit - A record with a hierarchy field ( { lvl0: string, lvl1: string, lvl2: string, ... } ).
 * @param components - Autocomplete components.
 * @returns An array of JSX.Elements | string, representing of the highlighted hierarchy starting from lvl1.
 *          Between each element, we insert a ' > ' character to render them as breadcrumbs eventually.
 */
function hierarchyToBreadcrumbs(hit, components) {
    const breadcrumbArray = [];
    let addedLevels = 0;
    if (!hit.hierarchy) {
        return breadcrumbArray;
    }
    for (let i = 1; i < 7 && addedLevels < 3; ++i) {
        if (hit.hierarchy[`lvl${i}`] && hit.hierarchy[`lvl${i}`].length > 0) {
            if (addedLevels > 0) {
                breadcrumbArray.push(' > ');
            }
            breadcrumbArray.push(jsx_runtime_1.jsx(components.Highlight, { hit: hit, attribute: "description" }, void 0));
            ++addedLevels;
        }
    }
    return breadcrumbArray;
}
function getSuggestionSnippet(hit, components) {
    var _a, _b;
    // If they are defined as `searchableAttributes`, 'description' and 'content' are always
    // present in the `_snippetResult`, even if they don't match.
    // So we need to have 1 check on the presence and 1 check on the match
    const description = (_a = hit._snippetResult) === null || _a === void 0 ? void 0 : _a.description;
    const content = (_b = hit._snippetResult) === null || _b === void 0 ? void 0 : _b.content;
    // Take in priority props that matches the search
    if (description && description.matchLevel === 'full') {
        return jsx_runtime_1.jsx(components.Snippet, { hit: hit, attribute: "description" }, void 0);
    }
    if (content && content.matchLevel === 'full') {
        return jsx_runtime_1.jsx(components.Snippet, { hit: hit, attribute: "content" }, void 0);
    }
    // Otherwise take the prop that was at least correctly returned
    if (description && !content) {
        return jsx_runtime_1.jsx(components.Snippet, { hit: hit, attribute: "description" }, void 0);
    }
    if (content) {
        return jsx_runtime_1.jsx(components.Snippet, { hit: hit, attribute: "content" }, void 0);
    }
    // Otherwise raw value or empty
    return hit.description || hit.content || '';
}
