"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.algoliasearchNetlify = void 0;
const AutocompleteWrapper_1 = require("./AutocompleteWrapper");
const defaultOptions = {
    analytics: true,
    hitsPerPage: 5,
    debug: false,
    poweredBy: true,
    placeholder: 'Search...',
    openOnFocus: false,
};
const mandatory = [
    'appId',
    'apiKey',
    'selector',
    'siteId',
    'branch',
];
const instances = [];
function algoliasearchNetlify(_options) {
    const options = Object.assign(Object.assign({}, defaultOptions), _options);
    for (const key of mandatory) {
        if (options[key])
            continue;
        throw new Error(`[algoliasearch-netlify] Missing mandatory key: ${key}`);
    }
    const autocomplete = new AutocompleteWrapper_1.AutocompleteWrapper(options);
    instances.push(autocomplete);
    // Wait for DOM initialization, then render
    const render = () => {
        autocomplete.render();
    };
    if (['complete', 'interactive'].includes(document.readyState)) {
        render();
    }
    else {
        document.addEventListener('DOMContentLoaded', render);
    }
}
exports.algoliasearchNetlify = algoliasearchNetlify;
